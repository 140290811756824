.App {
  text-align: center;
  background-color: #191e2a;
  height: 100vh;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.App-header {
  align-items: center;
  display: flex;
  background-color: #1b242c;
  color: #fff;
  height: 100px;
  min-height: 100px;
}
.App-header nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
}
.App-header-logo {
  color: #9f7aea;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
  padding-left: 20px;
  width: 141px;
}
.App-header-Link-Collection {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 20px;
  height: 100%;
  list-style: none;
  padding-right: 20px;
}
.App-header-Link {
  align-items: center;
  color: var(--font-color);
  display: flex;
  gap: 5px;
  justify-content: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  z-index: 2;
  font-weight: 700;
}
.split-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: calc(100% - 30rem);
  margin-top: 3rem;
  height: 75vh;
}

.line {
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.inputControls_button--primary{
  background-color: #9f7aea;
  color: #fff;
  font-size: 14px!important;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-decoration: none!important;
  text-transform: none;
  width: 100%;
}
