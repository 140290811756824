.card {
    background-color: #1F1F29;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    color: white;
    font-family: Arial, sans-serif;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.12);
    margin: auto;
}

.logoContainer {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    gap: 40px;
    align-items: center;
}

.logo {
    background-color: #000;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    border-radius: 10px;
}
.title {
    margin: 0px;
}
.details {
    display: flex;
    flex-direction: column;
}

.audits {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.audit {
    background-color: #333;
    padding: 5px 10px;
    border-radius: 5px;
}
.launchpad {
    background-color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.rating {
    display: flex;
    align-items: center;
    gap: 20px;
}

.grade {
    background-color: #6FCF6B;
    color: #FFF;
    border-radius: 5px;
    font-size: 20px;
    width: 90px;
    height: 70px;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.score {
    color: #6FCF6B;
    font-size: 40px;
    font-weight: bold;
}

.card p {
    font-size: 12px;
    margin-top: 10px;
    color: #45505b;
}