.container {
  background-color: rgba(36, 36, 53, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  box-shadow: 0 2px 10px #0000001a;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: 25rem;
  align-items: flex-start;
  font-family: monospace;
  color: #fff;
  width: 100%;
  height: 100%;
}
.terminal-text-field {
  padding: 8px;
  overflow: hidden;
}
.terminal-text-field pre {
  margin: 0px;
}
.terminal-header {
  width: calc(100% - 8px);
  background-color: #1e2127;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid rgba(22, 22, 32, 1);
  align-items: flex-start;
  display: flex;
  gap: 6px;
  padding: 4px;
}
.container p {
  margin: 0%;
}
.button-round {
  border-radius: 50%;
  height: 12px;
  border: none;
}
